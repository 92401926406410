<template>
  <v-row>
    <v-container>
      <v-row class="text-center mt-10">
        <v-col cols="12">
          <p>
            {{ $route.query.msg }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>
<script>
</script>
