<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>Documentation</h1>
        <p>
          le cloudlab est une Sandbox qui a pour but d'aider les collaborateurs de PER a prendre en main les services cloud. <br/>
          Pour l'instant un environnement aws est disponible pour monter en compétences. <br/><br/>

          Pour toutes question au projet vous pouvez contacter l'équipe du Cloudlab :
          <a href="mailto:percloudlab.fr@capgemini.com">percloudlab.fr@capgemini.com</a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup></script>
