// 2. Define some routes
// Each route should map to a component.

import AdministrationComponent from "@/components/AdministrationComponent.vue"
import DemandesEnCours from "@/components/DemandesEnCours.vue"
import DisplayMessage from "@/components/DisplayMessage.vue"
import DocumentationComponent from "@/components/DocumentationComponent.vue"
import RegisterComponent from "@/components/RegisterComponent.vue"
import ResetPassword from "@/components/ResetPassword.vue"
import { createRouter, createWebHistory } from "vue-router"

const routes = [
  { path: '/', component: DocumentationComponent },
  { path: '/reset', component: ResetPassword },
  { path: '/register', component: RegisterComponent },
  { path: '/adminRemove', component: AdministrationComponent },
  { path: '/gestionDemandes', component: DemandesEnCours },
  { path: '/displayMessage',name:"displayMessage", component: DisplayMessage},
]

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

router.beforeEach(async (to, from, next) => {
next()
  })

export default router