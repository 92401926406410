<template>
  <v-row class="d-flex justify-center text-center">
    <v-col cols="12" md="10"
      ><v-row>
        <v-col class="d-flex align-center" cols="12" md="3">
          <v-text-field
            label="Rechercher"
            append-inner-icon="mdi-magnify"
            single-line
            hide-details
            v-model="searchFilter"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex align-center" cols="12" md="3">
          <v-select
            :items="['Utilisateur', 'Creation demande']"
            label="Tri"
            hide-details
            v-model="sortBy"
          ></v-select>
        </v-col>
        <v-col class="d-flex align-center" cols="9" md="3">
          <v-select
            :items="['desc', 'asc']"
            label="Type"
            hide-details
            v-model="sortType"
          ></v-select>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <v-icon @click="updateItems"> mdi-sync </v-icon>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="10">
      <v-table>
        <thead>
          <tr>
            <th class="text-center">Utilisateur</th>
            <th class="text-center">Creation demande</th>
            <th class="text-center">Status</th>
            <th class="text-center">Accepter</th>
            <th class="text-center">Refuser</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in itemsDisplay" :key="item.UserName">
            <td>{{ item.email }}</td>
            <td>{{ new Date(item.CreateDate).toLocaleDateString() }}</td>
            <td>{{ item.status }}</td>
            <td v-if="item.status == 'REGISTRATION_STRAT'">
              <a
                :href="
                  configData.SERVER_URL_VALIDATE +
                  '?email=' +
                  item.email +
                  '&random_key=' +
                  item.token +
                  '&accept=True'
                "
              >
                <v-icon color="green"> mdi-account-check</v-icon></a
              >
            </td>
            <td v-if="item.status == 'REGISTRATION_STRAT'">
              <a
                :href="
                  configData.SERVER_URL_VALIDATE +
                  '?email=' +
                  item.email +
                  '&random_key=' +
                  item.token +
                  '&accept=False'
                "
              >
                <v-icon color="red"> mdi-account-cancel</v-icon></a
              >
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-col>

    <v-overlay
      v-model="apiInProgress"
      class="align-center justify-center"
      persistent
      z-index="1"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="200"
        width="15"
      ></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useMsal } from "../composition-api/useMsal";
import { loginRequest } from "../authConfig";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import configData from "@/config.json";
import api from "@/services/api";
const sortType = ref("asc");
const sortBy = ref(null);
const { instance, inProgress } = useMsal();

const items = ref([]);
const itemsDisplay = ref([]);
const searchFilter = ref(null);
const apiInProgress = ref(false);

onMounted(() => {
  updateItems();
});

watch(sortBy, (newValue) => {
  if (newValue == "Creation demande") {
    sortCreation();
  } else if (newValue == "Utilisateur") {
    sortUtilisateur();
  }
});

watch(sortType, () => {
  if (sortBy.value == "Creation demande") {
    sortCreation();
  } else if (sortBy.value == "Utilisateur") {
    sortUtilisateur();
  }
});

watch(searchFilter, (value) => {
  if (value && value != "") {
    itemsDisplay.value = items.value.filter((e) => find(value, e.email));
  } else {
    itemsDisplay.value = items.value;
  }
});

function find(txt, src) {
  // generate regex for all possibilities. for this case, it will generate "d?.?og|do?.?g|dog?.?" -> double .? are for 1 char insertion
  var re = new RegExp(
    txt
      .toUpperCase()
      .split("")
      .map(function (a, b) {
        return (
          txt.toUpperCase().substr(0, b) +
          a +
          "?.?" +
          txt.toUpperCase().substr(b + 1)
        );
      })
      .join("|"),
    "gi"
  );
  return src.toUpperCase().match(re) != null;
}

function sortCreation() {
  itemsDisplay.value.sort((o1, o2) => {
    let date1 = new Date(o1.CreateDate);
    let date2 = new Date(o2.CreateDate);
    return sortType.value == "desc"
      ? Number(date2) - Number(date1)
      : Number(date1) - Number(date2);
  });
}
function sortUtilisateur() {
  itemsDisplay.value.sort((o1, o2) => {
    return sortType.value == "desc"
      ? o1.email.localeCompare(o2.email)
      : -o1.email.localeCompare(o2.email);
  });
}

function updateItems() {
  apiInProgress.value = true;
  instance
    .acquireTokenSilent({
      ...loginRequest,
    })
    .then((res) => {
      if (inProgress.value === InteractionStatus.None) {
        api
          .get(configData.SERVER_URL_GET_DEMANDES, {
            headers: {
              Authorization: `Bearer ${res.accessToken}`,
            },
          })
          .then(function (response) {
            items.value = response.data.Users;
            itemsDisplay.value = items.value;
            itemsDisplay.value.sort((o1, o2) => {
              return -o1.status.localeCompare(o2.status);
            });
          })
          .finally(() => {
            apiInProgress.value = false;
          });
      }
    })
    .catch(async (e) => {
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(loginRequest);
      }
      apiInProgress.value = false;
    });
}
</script>

<style lang="scss" scoped></style>
