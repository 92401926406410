<template>
  <v-row class="d-flex justify-center text-center">
    <v-col cols="12" md="6">
      <template v-if="isAuthenticated">
        <h1>Réinitialiser Mots de passe</h1>
        <v-card class="px-6 py-6"
          ><v-form ref="form">
            <v-row>
              <v-col>
                Adresse email :
                <v-text-field v-model="email" />
              </v-col>
            </v-row>
            <v-btn @click="send">Valider</v-btn>
            <v-progress-linear
            v-if="inProgressAPI"
      indeterminate
      color="cyan"
    ></v-progress-linear>
          </v-form>
        </v-card>
      </template>
      <template v-else>
        <v-container>
          <v-row class="text-center">
            <v-col cols="12">
              <h1>Authentification néccessaire</h1>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-col>
  </v-row>
</template>

<script setup>
import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import { useMsal } from "@/composition-api/useMsal";
import api from "@/services/api";
import configData from "@/config.json";
import { loginRequest } from "@/authConfig";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useRouter } from "vue-router";
import { ref } from "vue";

const isAuthenticated = useIsAuthenticated();
const inProgressAPI=ref(false);

const { accounts, instance, inProgress } = useMsal();
const routeur = useRouter();

let email;
if (accounts.value[0]) {
  email = accounts.value[0].username;
}

async function send() {
  inProgressAPI.value=true;
  const response = await instance
    .acquireTokenSilent({
      ...loginRequest,
    })
    .catch(async (e) => {
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(loginRequest);
      }
      throw e;
    });
  if (inProgress.value === InteractionStatus.None) {
    api.post(
      configData.SERVER_URL_RESET_PASSWORD,
      {
        email: email.value,
      },
      {
        headers: {
          Authorization: `Bearer ${response.accessToken}`,
        },
      }
    ).then(function (response) {
      routeur.push({ path: `/displayMessage`,query: response.data})

}).finally(()=>{
  inProgressAPI.value=false;
});
  }
}
</script>
