<template>
  <div>
    <v-app-bar>
      <v-toolbar dark fixed app>
        <v-toolbar-title>PER CloudLab</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn to="/">A propos</v-btn>

          <v-btn to="/register">Inscription</v-btn>
          <v-btn to="/reset">Mots de passe oublié</v-btn>
          <v-btn v-if="admin" id="menu-admin">Administration</v-btn>

          <v-menu v-if="admin" activator="#menu-admin">
            <v-list nav>
              <v-list-item to="/adminRemove"> Gestion comptes </v-list-item>
          <v-list-item to="/gestionDemandes">
            Demandes en attentes
          </v-list-item>
            </v-list>
          </v-menu>

          <v-btn v-if="!isAuthenticated" v-on:click="loginRedirect"
            >login</v-btn
          >
          <v-btn v-if="isAuthenticated" v-on:click="logoutRedirect"
            >logout</v-btn
          >
          <v-btn>{{ name }}</v-btn>
        </v-toolbar-items>
        <v-app-bar-nav-icon
          variant="text"
          @click.stop="drawer = !drawer"
          class="hidden-md-and-up"
        ></v-app-bar-nav-icon>
      </v-toolbar>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" bottom temporary>
      <v-list nav>
        <v-list-item prepend-icon="mdi-account">{{ name }}</v-list-item>
        <v-list-item prepend-icon="mdi-home" to="/">A propos</v-list-item>
        <v-list-item prepend-icon="mdi-account-plus" to="/register"
          >Inscription</v-list-item
        >
        <v-list-item prepend-icon="mdi-form-textbox-password" to="/reset"
          >Mots de passe oublié</v-list-item
        >

        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-cog-outline"
              v-if="admin"
              >Administration</v-list-item
            >
          </template>

          <v-list-item to="/adminRemove"> Gestion comptes </v-list-item>
          <v-list-item to="/gestionDemandes">
            Demandes en attentes
          </v-list-item>
        </v-list-group>

        <v-list-item
          prepend-icon="mdi-login"
          v-if="!isAuthenticated"
          v-on:click="loginRedirect"
          >login</v-list-item
        >
        <v-list-item
          prepend-icon="mdi-logout"
          v-if="isAuthenticated"
          v-on:click="logoutRedirect"
          >logout</v-list-item
        >
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script setup>
import { useMsal } from "../composition-api/useMsal";
import { computed, onMounted, ref } from "vue";
import { loginRequest } from "../authConfig";
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import { InteractionStatus } from "@azure/msal-browser";
import api from "@/services/api";
import configData from "@/config.json";
import { onBeforeRouteUpdate } from "vue-router";
const drawer = ref(false);
const isAuthenticated = useIsAuthenticated();
const { accounts, instance, inProgress } = useMsal();
const admin = ref(false);

const loginRedirect = () => {
  instance.loginRedirect(loginRequest);
};
const logoutRedirect = () => {
  instance.logoutRedirect();
};

const name = computed(() => {
  if (accounts.value.length > 0) {
    const name = accounts.value[0].name;
    if (name) {
      return name;
    }
  }
  return "";
});

onBeforeRouteUpdate(() => {
  flagAdmin();
});
onMounted(() => {
  flagAdmin();
});

function flagAdmin() {
  if (useIsAuthenticated()) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
      })
      .then((res) => {
        if (inProgress.value === InteractionStatus.None) {
          api
            .get(configData.SERVER_URL_IS_ADMIN, {
              headers: {
                Authorization: `Bearer ${res.accessToken}`,
              },
            })
            .then(function (response) {
              if (response.data.admin) admin.value = true;
            });
        }
      })
      .catch(async (e) => {
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(loginRequest);
        }
        admin.value = false;
      });
  } else {
    admin.value = false;
  }
}
</script>
